<template>
  <div class="supplier-handler pd-20">
    <a-page-header
      class="mb-10"
      style="padding: 0"
      sub-title=""
      @back="$router.go(-1)"
    >
      <div slot="title">
        <h1>
          {{
            $route.name === "edit"
              ? "Editar"
              : $route.name === "view"
              ? "Visualizar"
              : "Novo"
          }}
          Fornecedor
          <a-divider v-if="$root.isAdmin()" type="vertical" />
          <a-tooltip
            v-if="$root.isAdmin() && $route.name === 'view'"
            placement="top"
            title="Editar"
          >
            <a class="edit cgreen" @click="edit(supplier.id)">
              <a-icon type="edit-svg" />
            </a>
          </a-tooltip>

          <a-tooltip
            v-if="$root.isAdmin() && $route.name === 'edit'"
            placement="top"
            title="Ver"
          >
            <a class="edit cblue" @click="view(supplier.id)">
              <a-icon type="eye-svg" />
            </a>
          </a-tooltip>
        </h1>
      </div>
    </a-page-header>

    <a-form
      class="form-contracts"
      :form="supplierForm"
      @submit="submitSupplier"
    >
      <a-row :gutter="[20, 20]">
        <a-col class="box box-contractor" :span="8">
          <a-row :gutter="[20, 0]">
            <h2 class="title">DADOS DO FORNECEDOR</h2>
            <supplier-data :supplierForm="supplierForm" />
          </a-row>
        </a-col>

        <a-col
          class="box box-services"
          :span="16"
          v-if="$route.name === 'edit'"
        >
          <a-row :gutter="[0, 0]">
            <a-row class="title-box mb-20" type="flex" justify="space-between">
              <a-col>
                <h3>DADOS BANCÁRIOS</h3>
              </a-col>
              <a-col>
                <a-button type="link" size="small" ghost @click="addBank">
                  <a-icon type="plus" /> ADICIONAR NOVO BANCO
                </a-button>
              </a-col>
            </a-row>
            <br />
            <a-row v-for="(bank, index) in bankRows" :key="index" :gutter="20">
              <a-col :span="22">
                <supplier-bank-data
                  :supplierForm="supplierForm"
                  :bankId="bank.id"
                />
              </a-col>
              <a-col :span="2">
                <a-button
                  class="mt-10"
                  size="small"
                  type="danger"
                  shape="circle"
                  icon="delete"
                  v-if="bankRows.length == bank.id"
                  @click="removeBank(bank.id)"
                >
                </a-button>
              </a-col>
            </a-row>

            <a-empty
              class="mt-30 mb-30"
              :image="simpleImage"
              v-if="bankRows == 0"
            >
              <span slot="description"> Nenhum dado bancário cadastrado. </span>
            </a-empty>

            <a-form-item style="display: none">
              <a-input
                class="travel-input"
                v-decorator="[
                  `bank_rows`,
                  {
                    initialValue: JSON.stringify(bankRows),
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-row>
        </a-col>

        <a-col
          class="box box-services"
          :span="16"
          v-if="$route.name === 'view'"
        >
          <a-row :gutter="[0, 0]">
            <h2 class="title">
              <a-row>
                <a-col :span="12">DETALHES DO FORNECEDOR</a-col>
              </a-row>
            </h2>
            <a-tabs
              class="portal-tabs travel-tabs with-icons vertical payment-tabs"
              default-active-key="1"
              tab-position="left"
            >
              <a-tab-pane key="1">
                <template slot="tab">
                  <a-tooltip placement="right">
                    <template slot="title"> Cartões de Milhas </template>
                    <a-icon type="credit-card" />
                  </a-tooltip>
                </template>
                <div style="padding: 0 10px">
                  <a-table
                    class="travel-table last-itens"
                    :pagination="false"
                    :columns="[
                      {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id',
                        class: 'id',
                        width: 80,
                      },

                      {
                        title: 'Pgm. Milhas',
                        dataIndex: 'miles_program',
                        key: 'miles_program',
                      },
                      {
                        title: 'Total Milhas',
                        dataIndex: 'total_miles',
                        key: 'total_miles',
                        class: 'total-miles',
                      },
                      {
                        title: 'Disponível',
                        dataIndex: 'available_miles',
                        key: 'available_miles',
                        class: 'available-miles',
                      },
                      {
                        title: 'Utilizado',
                        dataIndex: 'spent_miles',
                        key: 'spent_miles',
                        class: 'spent-miles',
                      },

                      {
                        title: 'Validade',
                        dataIndex: 'expires_at',
                        key: 'expires_at',
                        scopedSlots: {
                          customRender: 'expires_at',
                        },
                      },

                      {
                        title: '',
                        align: 'right',
                        fixed: 'right',
                        scopedSlots: {
                          customRender: 'action',
                        },
                      },
                    ]"
                    :data-source="milesCards.list"
                    :loading="milesCards.loading"
                  >
                    <template slot="id" slot-scope="record">
                      {{ record.id }}
                    </template>

                    <template slot="quantity" slot-scope="record">
                      {{ record.id }}
                    </template>
                    <template slot="spent_miles" slot-scope="record">
                      {{ record.spent_miles }}
                    </template>
                    <template slot="price_per_miles" slot-scope="record">
                      R$ {{ record }}
                    </template>
                    <template slot="special_price" slot-scope="record">
                      R$ {{ record }}
                    </template>
                    <template slot="selling_price" slot-scope="record">
                      R$ {{ record }}
                    </template>
                    <template slot="expires_at" slot-scope="record">
                      {{ formatDate(record) }}
                    </template>
                    <div slot="action" slot-scope="record">
                      <a-tooltip placement="top" title="Ver">
                        <a class="view ml-20" @click="viewMilesCard(record)">
                          <a-icon
                            slot="prefix"
                            type="eye-svg"
                            style="zoom: 0.034"
                          />
                        </a>
                      </a-tooltip>
                    </div>
                  </a-table>

                  <center>
                    <a-pagination
                      show-size-changer
                      :default-current="milesCards.pagination.page"
                      :page-size.sync="milesCards.pagination.perPage"
                      :total="milesCards.pagination.total"
                      @change="changePage"
                      @showSizeChange="changePageSize"
                    >
                    </a-pagination>
                  </center>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2">
                <template slot="tab">
                  <a-tooltip placement="right">
                    <template slot="title"> Dados bancários </template>
                    <a-icon type="bank" />
                  </a-tooltip>
                </template>
                <a-row
                  v-for="bank in bankRows"
                  :key="bank.id"
                  class="view-data"
                  style="padding: 15px 5px"
                  :gutter="15"
                >
                  <a-col :span="10">
                    <div class="label">Banco</div>
                    <div class="value">
                      {{ supplier.meta[`bank_${bank.id}`] }}
                    </div>
                  </a-col>
                  <a-col :span="4">
                    <div class="label">Agência</div>
                    <div class="value">
                      {{ supplier.meta[`bank_${bank.id}_agency`] }}
                    </div>
                  </a-col>

                  <a-col :span="6">
                    <div class="label">Conta</div>
                    <div class="value">
                      {{ supplier.meta[`bank_${bank.id}_account`] }}
                    </div>
                  </a-col>

                  <a-col :span="4">
                    <div class="label">Dígito</div>
                    <div class="value">
                      {{ supplier.meta[`bank_${bank.id}_account_digit`] }}
                    </div>
                  </a-col>
                </a-row>
                <a-empty
                  class="mt-30 mb-30"
                  :image="simpleImage"
                  v-if="bankRows == 0"
                >
                  <span slot="description">
                    Nenhum dado bancário cadastrado.
                  </span>

                  <a-button @click="viewSupplier()">
                    Cadastrar conta bancária
                  </a-button>
                </a-empty>
              </a-tab-pane>
              <a-tab-pane key="3">
                <template slot="tab">
                  <a-tooltip placement="right">
                    <template slot="title"> Pagamentos </template>
                    <a-icon type="dollar" />
                  </a-tooltip>
                </template>
                <div style="padding: 0 10px">
                  <logs-timeline :logs="{ data: [] }" />
                </div>
              </a-tab-pane>

              <a-tab-pane key="4">
                <template slot="tab">
                  <a-tooltip>
                    <template slot="title"> Documentos </template>
                    <a-icon type="file-text" />
                  </a-tooltip>
                </template>
                <div style="padding: 0 10px">
                  <FilesTable
                    moduleName="supplier"
                    :moduleId="$router.history.current.params.id"
                    :searchModuleIds="$router.history.current.params.id"
                    searchModules="supplier"
                    s3Folder="suppliers"
                  />
                </div>
              </a-tab-pane>

              <a-tab-pane key="5">
                <template slot="tab">
                  <a-tooltip>
                    <template slot="title"> Logs </template>
                    <a-icon type="history" />
                  </a-tooltip>
                </template>
                <div style="padding: 0 10px">
                  <logs-timeline :logs="{ data: [] }" />
                </div>
              </a-tab-pane>
            </a-tabs>
          </a-row>
        </a-col>
      </a-row>

      <a-row
        type="flex"
        justify="end"
        :gutter="[20, 20]"
        v-if="$route.name !== 'view'"
      >
        <a-button
          class="mr-10 mt-10"
          size="large"
          :loading="processing"
          type="primary"
          html-type="submit"
        >
          {{
            $route.name === "edit"
              ? "SALVAR ALTERAÇÕES"
              : "CADASTRAR FORNECEDOR"
          }}
        </a-button>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { Empty } from "ant-design-vue";
import { format, parse } from "date-fns";

import SupplierData from "@/components/suppliers/SupplierHandler/SupplierData";
import SupplierBankData from "@/components/suppliers/SupplierHandler/SupplierBankData.vue";
import LogsTimeline from "@/components/logs/LogsTimeline.vue";
import suppliersMixins from "@/mixins/suppliersMixins";
import FilesTable from "@/components/general/FilesTable.vue";

export default {
  name: "SupplierHandler",
  components: { SupplierData, SupplierBankData, LogsTimeline, FilesTable },
  mixins: [suppliersMixins],
  beforeMount() {
    const action = this.$route.name;
    const id = this.$router.history.current.params.id;
    if (action === "edit" || action === "view") {
      this.getSupplier(id);
      this.milesCards.pagination.perPage = 30;
      this.milesCards.filters.supplier = id;
      this.getMilesCards();
    }
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    this.$root.$on("getSupplierRes", (data) => {
      this.supplier = data;
      this.listMilesCards = data.miles_cards.data;
      this.supplierForm.setFieldsValue(data);
      this.bankRows = JSON.parse(this.supplier.meta.bank_rows);
      setTimeout(() => {
        this.bankRows.forEach((bank) => {
          this.supplierForm.setFieldsValue({
            [`bank_${bank.id}`]: this.supplier.meta[`bank_${bank.id}`],
            [`bank_${bank.id}_agency`]:
              this.supplier.meta[`bank_${bank.id}_agency`],
            [`bank_${bank.id}_account`]:
              this.supplier.meta[`bank_${bank.id}_account`],
            [`bank_${bank.id}_account_digit`]:
              this.supplier.meta[`bank_${bank.id}_account_digit`],
          });
        });
      }, 20);
    });
  },
  data() {
    return {
      supplier: {},
      supplierForm: this.$form.createForm(this),
      milesCardsLoading: false,
      listMilesCards: [],
      bankRows: [],
      milesCards: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          loadingResults: false,
          users: {
            list: [],
            selected: [],
          },
          airlines: {
            list: [],
            selected: [],
          },
          milesPrograms: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Concluído",
                value: "concluded",
              },
              {
                label: "Em edição",
                value: "edit",
              },
              {
                label: "Cancelado",
                value: "canceled",
              },
            ],
            selected: ["concluded", "edit"],
          },
          period: {
            selected: [],
          },
          supplier: "",
          id: "",
          available_miles: "",
          price: {
            min: "",
            max: "",
          },
          order: "desc",
          orderBy: "created",
        },
      },
    };
  },
  methods: {
    submitSupplier(e) {
      e.preventDefault();
      this.supplierForm.validateFields((err, supplier) => {
        let body = {
          ...supplier,
          user_id: this.$store.state.userData.id,
        };
        if (!err) {
          this.processing = true;
          const action = this.$route.name;
          if (action === "create") {
            this.$http
              .post("/supplier/create", body)
              .then(({ data: { message } }) => {
                this.$message.success(message);
                this.$router.push("/suppliers/list");
              })
              .catch(
                ({
                  response: {
                    data: { message },
                  },
                }) => {
                  this.$message.error(message);
                }
              )
              .finally(() => {
                this.processing = false;
              });
          } else {
            body.id = this.$router.history.current.params.id;
            this.$http
              .post("/supplier/update", body)
              .then(({ data: { message } }) => {
                this.$message.success(message);
              })
              .catch(
                ({
                  response: {
                    data: { message },
                  },
                }) => {
                  this.$message.error(message);
                }
              )
              .finally(() => {
                this.processing = false;
              });
          }
        }
      });
    },
    getMilesCards() {
      this.milesCards.loading = true;

      let filters = "";

      if (this.milesCards.filters.id) {
        filters += `&id=${this.milesCards.filters.id}`;
      }

      if (this.milesCards.filters.supplier) {
        filters += `&supplier=${this.milesCards.filters.supplier}`;
      }

      if (!this.$root.isAdmin() && !this.$root.isMilesManager()) {
        filters += `&user=${this.$store.state.userData.id}`;
      } else {
        if (this.milesCards.filters.users.selected.length > 0) {
          filters += `&user=${this.milesCards.filters.users.selected}`;
        }
      }

      if (this.activeTab == "available") {
        filters += "&status=available";
      }

      if (this.activeTab == "blocked") {
        filters += "&status=blocked";
      }

      if (this.activeTab == "canceled") {
        filters += "&status=canceled";
      }

      if (this.milesCards.filters.airlines.selected.length > 0) {
        filters += `&airline=${this.milesCards.filters.airlines.selected}`;
      }

      if (this.milesCards.filters.milesPrograms.selected.length > 0) {
        filters += `&miles-program=${this.milesCards.filters.milesPrograms.selected}`;
      }

      if (this.milesCards.filters.period.selected.length > 0) {
        filters += `&period=${this.milesCards.filters.period.selected[0]}|${this.milesCards.filters.period.selected[1]}`;
      }

      if (this.milesCards.filters.available_miles) {
        filters += `&available_miles=${this.milesCards.filters.available_miles}`;
      }

      filters += `&order=${this.milesCards.filters.order}&order-by=${this.milesCards.filters.orderBy}`;

      this.$http
        .get(
          `/miles-card/list?page=${this.milesCards.pagination.page}&per_page=${this.milesCards.pagination.perPage}${filters}`
        )
        .then(
          ({
            data: {
              data,
              meta: { total },
              totalMiles,
              totalAvailableMiles,
              totalSpentMiles,
              totalMoney,
              totalAvailableMoney,
              totalMoneySpent,
            },
          }) => {
            totalAvailableMiles;
            totalSpentMiles;
            this.pagination.total = total;

            this.milesCards.pagination.total = total;
            this.milesCards.list = [...data];
            this.listMilesCards = [...data];

            this.totalMilesCards = total;

            this.totalMiles = totalMiles;
            this.totalAvailableMiles = totalAvailableMiles;
            this.totalSpentMiles = totalSpentMiles;

            this.totalMoney = totalMoney;
            this.totalAvailableMoney = totalAvailableMoney;
            this.totalMoneySpent = totalMoneySpent;

            this.excelFile.data = [...data];

            this.excelFile.footer = [];

            this.excelFile.footer.push(" ");

            this.excelFile.footer.push(`Nº CARTÕES: ${total}`);

            this.excelFile.footer.push(`TOTAL MILHAS: ${totalMiles}`);

            this.excelFile.footer.push(`DISPONÍVEL: R$ ${totalAvailableMiles}`);

            this.excelFile.footer.push(`UTILIZADO: R$ ${totalSpentMiles}`);
          }
        )
        .catch(({ response }) => {
          this.pagination.total = response.data.meta.total;
          this.milesCards.list = response.data.data;

          this.totalMilesCards = response.data.totalMiles;

          this.totalMiles = response.data.totalMiles;
          this.totalAvailableMiles = response.data.totalAvailableMiles;
          this.totalSpentMiles = response.data.totalSpentMiles;

          this.totalMoney = response.data.totalMoney;
          this.totalAvailableMoney = response.data.totalAvailableMoney;
          this.totalMoneySpent = response.data.totalMoneySpent;

          this.excelFile.data = response.data.data;
        })
        .finally(() => {
          this.milesCards.loading = false;
        });
    },
    changePage(current) {
      this.milesCards.pagination.page = current;
      this.getMilesCards();
    },
    changePageSize(current, pageSize) {
      this.milesCards.pagination.page = current;
      this.milesCards.pagination.perPage = pageSize;

      this.getMilesCards();
    },
    addBank() {
      this.bankRows.push({
        id: this.bankRows.length + 1,
      });
    },
    removeBank(id) {
      for (var i in this.bankRows) {
        if (this.bankRows[i].id == id) {
          this.bankRows.splice(i, 1);
          break;
        }
      }
    },
    viewMilesCard(milesCard) {
      this.$router.push(`/miles-cards/list`);
      this.$store.state.viewMilesCard = true;
      this.$store.state.theMilesCard = milesCard;
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd", new Date());
      return format(theDate, "dd/MM/yyyy");
    },
    viewSupplier() {
      setTimeout(() => {
        this.$router.push(`/suppliers/edit/${this.supplier.id}`);
      }, 100);
    },
    view(id) {
      this.$router.push(`/suppliers/view/${id}`);
    },
    edit(id) {
      this.$router.push(`/suppliers/edit/${id}`);
    },
  },
};
</script>
