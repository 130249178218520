var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('a-col',{attrs:{"span":10}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.supplierForm.getFieldValue(`bank_${_vm.bankId}`) ? 'filled' : '',attrs:{"for":""}},[_vm._v("Banco")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `bank_${_vm.bankId}`,
          {
            rules: [
              {
                required: true,
                message: 'Obrigatório',
              },
            ],
          },
        ]),expression:"[\n          `bank_${bankId}`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Obrigatório',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",attrs:{"show-search":"","placeholder":"Banco"}},_vm._l((_vm.banks),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.nome}},[_vm._v("\n          "+_vm._s(item.numero)+" - "+_vm._s(item.nome)+"\n        ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',[_c('label',{class:_vm.supplierForm.getFieldValue(`bank_${_vm.bankId}_agency`) ? 'filled' : '',attrs:{"for":""}},[_vm._v("\n        Agência\n      ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `bank_${_vm.bankId}_agency`,
          { rules: [{ required: true, message: 'Obrigatório' }] },
        ]),expression:"[\n          `bank_${bankId}_agency`,\n          { rules: [{ required: true, message: 'Obrigatório' }] },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Agência"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('label',{class:_vm.supplierForm.getFieldValue(`bank_${_vm.bankId}_account`) ? 'filled' : '',attrs:{"for":""}},[_vm._v("\n        Conta\n      ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `bank_${_vm.bankId}_account`,
          { rules: [{ required: true, message: 'Obrigatório' }] },
        ]),expression:"[\n          `bank_${bankId}_account`,\n          { rules: [{ required: true, message: 'Obrigatório' }] },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Conta"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":3}},[_c('a-form-item',[_c('label',{class:_vm.supplierForm.getFieldValue(`bank_${_vm.bankId}_account_digit`)
            ? 'filled'
            : '',attrs:{"for":""}},[_vm._v("\n        Dígito\n      ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `bank_${_vm.bankId}_account_digit`,
          { rules: [{ required: true, message: 'Obrigatório' }] },
        ]),expression:"[\n          `bank_${bankId}_account_digit`,\n          { rules: [{ required: true, message: 'Obrigatório' }] },\n        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Dígito"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }